body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contentLayout {
  border-radius: 10px;
  padding: 5px;
  margin: 10px;
  box-shadow: 5px 10pxr rgba(63, 63, 68, 0.1);
  background-color: #ffffff;
}

.contentHeader {
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
  padding: 8px;
  margin-bottom: 10px;
  background-color: #fd560eb5;
  color: #ffffff;
  border-radius: 2px;
  font-weight: 550;
}

.contentFieldSet {
  padding: 10px;
  margin: 0 2px 0 0;
  border: 1px solid #fd560eb5;
  color: #fd560eb5;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 550;
}

.tableHeader {
  color: #000000;
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #797878;
  border-left: 4px solid #fd560eb5;
}

.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
  background-color: #797878;
  color: #ffffff;
}

.ant-table-filter-trigger-container .ant-table-filter-trigger-container-open {
  background-color: #797878;
}

/* .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  li:hover {
  background-color: #797878;
} */
.ant-table-thead>tr>th {
  background-color: #5d5d5d;
  color: #ffffff;
  border-radius: 1px;
}

.ant-table-thead>tr>th:hover {
  background-color: #797878;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #797878;
}

.ant-table-thead th.ant-table-column-sort {
  background: #797878;
}

/* #components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
} */
.divContainer {
  position: fixed;
  top: 40%;
  left: 20%;
  width: 65vh;
  height: 38vh;
  margin-top: -9em;
  /*set to a negative number 1/2 of your height*/
  margin-left: -15em;
  /*set to a negative number 1/2 of your width*/
  padding: 20px;
  border-radius: 4px;
  box-shadow: -4px 5px 7px -6px #797878;
  background-color: #ffffff;
  opacity: 0.94;
}

/* .ant-menu-item .ant-menu-item-active {
  background-color: #797878;
} */
body,
html {
  height: 100%;
  margin: 0;
  background-image: url(../src/assets/TataHitachiBackground.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.anchor:hover {
  text-decoration: underline;
}

.ant-spin-dot-item {
  background-color: #ed691f;
  opacity: inherit;
}

.ant-spin-dot-item:nth-child(2) {
  opacity: 0.7;
}

.ant-spin-dot-item:nth-child(3) {
  opacity: 0.5;
}

.ant-spin-dot-item:nth-child(4) {
  opacity: 0.3;
}

.ant-switch-checked {
  background-color: #38a169;
}

.dialog-window {
  height: 200px;
  border: 1px black solid;
}

.scrollable-content {
  height: 180px;
  overflow: auto;
  background-color: blue;
}

.footer {
  height: 20px;
  background-color: green;
}

/* .ant-menu-dark .ant-menu-item :hover {
  background-color: #797878;
  border-left: 4px solid #fd560eb5;
} */
.ant-card-head {
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;

  border-radius: 2px;
  font-weight: 550;
}

.ant-modal-content {
  max-height: 10000px;
}

.ant-modal-header {
  background-color: #ed691f;
}

.ant-modal-title {
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  font-weight: 600;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: #797878;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  font-weight: 600;
}

.ant-collapse {
  border: none;
}

.ant-modal-close-x {
  color: white;
  font-weight: 800;
}

.tdHeader {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

.tableServiceDetails {
  border-collapse: separate;
  border-spacing: 0 0.7em;
}

.modalTable {
  border-radius: 4px;
  box-shadow: -4px 5px 7px -6px #797878;
  margin-top: 20px;
}

.modalTableHeader {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  margin-top: 10px;
  text-align: center;
}

.salesToolMasterDiv {
  display: flex;
  max-width: 100%;
}

.salesToolChildDiv {
  background-color: white;
  width: inherit;
  margin-bottom: 10px;
  padding: 10px;
  color: #f37435;
  box-shadow: rgb(0, 0, 0, 0.44) 0px 5px 5px;
  margin: 1%;
  cursor: pointer;
  display: inline-block;
}

.salesTools {
  margin: 1%;
}

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
  border-color: #f37435;

  &:hover {
    color: rgb(69, 152, 246);
  }
} */

/* .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  border-color: #f37435;

  &:hover {
    color: rgb(69, 152, 246);
  }
} */